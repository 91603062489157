import { BaseRequest, PagingRequest } from '../base';
import { IApiListResponse } from 'Services/policies/new/service';
import { LeadStatusItem } from './interfaces';

export class ListLeadStatusesRequest extends BaseRequest<IApiListResponse<LeadStatusItem>> {
  constructor(
    paging: PagingRequest,
  ) {
    const queryParams = new URLSearchParams();
    queryParams.set( 'pageSize', paging.pageSize.toString() );
    queryParams.set( 'pageToken', paging.pageToken.toString() );

    if ( paging.filter.length ) {
      const filterParams = paging.filter
        .map( ( item ) => `${item.id}=${encodeURIComponent( item.value )}` )
        .join( '&' );
      queryParams.set( 'filter', filterParams );
    }

    if ( paging?.filters?.length ) {
      const filterParams = paging.filters
        .map( ( item ) => `${item.id}${item.operator}${encodeURIComponent( item.value )}` )
        .join( ';' );
      queryParams.set( 'filters', filterParams );
    }

    super( {
      method: 'GET',
      url: '/insuranceservice/v1/lead-statuses',
      responseType: 'json',
      params: queryParams,
    } );
  }
}
