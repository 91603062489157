import { BaseRequest, PagingRequest } from '../base';
import { getQueryParamsForListAPI } from '../api';
import { ListBankAccountsResponse, ListPaymentMethodsResponse } from './interfaces';

export class ListBankAccountRequest extends BaseRequest<ListBankAccountsResponse> {
  constructor(
    paging: PagingRequest,
    expand: string[] = [ ],
  ) {
    const queryParams = getQueryParamsForListAPI( paging );

    if ( expand && expand.length > 0 ) {
      const expandPars = expand.map( ( item ) => `${encodeURIComponent( item )}` ).join( ',' );
      queryParams.set( 'expand', expandPars );
    }

    super( {
      method: 'GET',
      url: '/paymentservice/v1/bank-accounts',
      responseType: 'json',
      params: queryParams,
    } );
  }
}

export class ListPaymentMethodRequest extends BaseRequest<ListPaymentMethodsResponse> {
  constructor(
    paging: PagingRequest,
  ) {
    super( {
      method: 'GET',
      url: '/paymentservice/v1/payment-methods',
      responseType: 'json',
      params: getQueryParamsForListAPI( paging ),
    } );
  }
}
